import React from "react";

import { ArticleT } from "@faq/types";

import { toArticle } from "@faq/utils/routes";
import { truncate } from "@faq/utils/articles";

import Block from "../Block";

import * as s  from "./SearchResults.module.css";

type SearchResultsProps = {
  articles: ArticleT[];
  query: string;
};

const SearchResults: React.FC<SearchResultsProps> = ({ articles, query }) => {
  if (articles.length === 0)
    return <div className={s.title}>Aucun résultat pour "{query}"</div>;

  return (
    <>
      <div className={s.title}>Résultat de la recherche "{query}"</div>
      <div>
        {articles?.map(({ slug, title, content, category }, i) => (
          <Block
            key={i}
            to={toArticle(slug, category.slug)}
            title={title}
            content={truncate(content.replace(/\*/g, ""), 300, "...")}
          />
        ))}
      </div>
    </>
  );
};

export default SearchResults;
