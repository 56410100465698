import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import WhiteLogo from "@faq/assets/icons/Logo_artelink_white.svg";
import SearchIcon from "@faq/assets/icons/icon_search.svg";
import CloseIcon from "@faq/assets/icons/icon_close_menu.svg";
import LinkIcon from "@faq/assets/icons/external-link-alt-solid.svg";

import Wrapper from "@faq/components/Wrapper";

import * as s  from "./Header.module.css";

type HeaderProps = {
  setResults: (results: [] | null) => void;
  setVal: (val: string) => void;
  val: string;
};

const Header: React.FC<HeaderProps> = ({ setResults, setVal, val }) => {
  const [loading, setLoading] = useState(false);
  const {
    allStrapiArticle: { nodes },
  } = useStaticQuery(graphql`
    query HeadingQuery {
      allStrapiArticle {
        nodes {
          title
          content
          slug
          category {
            slug
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (val.length > 2) {
      setResults(
        nodes.filter(
          ({ title, content }) =>
            title.toLowerCase().includes(val.toLowerCase()) ||
            content.toLowerCase().includes(val.toLowerCase())
        )
      );
    } else setResults(null);
    setLoading(false);
  }, [val]);

  return (
    <header className={s.wrapper}>
      <Wrapper>
        <div className={s.header}>
          <Link to="/" onClick={() => setVal("")}>
            <WhiteLogo className={s.logo} />
          </Link>
          <a href="http://artelink.fr/" className={s.link}>
            <LinkIcon className={s.linkIcon} />
            Retourner sur artelink.fr
          </a>
        </div>
        <h1 className={s.title}>Site support Artelink</h1>
        <div className={s.inputWrapper}>
          <input
            placeholder="Rechercher"
            className={s.input}
            onChange={(e) => {
              const value = e.target.value;
              setVal(value);
              if (value.length > 2) setLoading(true);
            }}
            value={val}
          />
          <div>
            <SearchIcon className={`${s.searchIcon} ${s.icon}`} />
            {val.length > 0 && (
              <CloseIcon
                className={`${s.closeIcon} ${s.icon}`}
                onClick={() => setVal("")}
              />
            )}
          </div>
        </div>
      </Wrapper>
    </header>
  );
};

export default Header;
