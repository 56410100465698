import React, { useState } from "react";
import SearchResults from "../SearchResults";
import Wrapper from "../Wrapper";
import Breadcrumb from "./components/Breadcrumb";

import Footer from "./components/Footer";
import Header from "./components/Header";
import SEO from "./components/SEO";

import * as s  from "./Layout.module.css";

type LayoutProps = {
  breadcrumb?: Array<{ to?: string; label: string }>
  seo?: {
    pageTitle?: string;
    pageDescription?: string;
    pageUrl?: string;
    pageImg?: string;
  };
};

const Layout: React.FC<LayoutProps> = ({ breadcrumb, seo, children }) => {
  const [results, setResults] = useState(null);
  const [val, setVal] = useState("");

  return (
    <div className={s.wrapper}>
      <SEO {...seo} />
      <Header setResults={setResults} setVal={setVal} val={val} />
      <main className={s.content}>
        <Wrapper>
          {!!breadcrumb && results === null && (
            <Breadcrumb
              breadcrumb={[
                { to: "/", label: "Toutes les catégories" },
                ...breadcrumb,
              ]}
            />
          )}
          {Array.isArray(results) ? (
            <SearchResults articles={results} query={val} />
          ) : (
            children
          )}
        </Wrapper>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
