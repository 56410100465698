import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

import * as s from "./Block.module.css";


type BlockProps = {
  title: string;
  content?: string | JSX.Element;
  articlesNb?: number;
  to?: string;
};

const Block: React.FC<BlockProps> = ({
  title,
  content,
  to,
  articlesNb = 0,
}) => {
  const mutlipleArticles = articlesNb > 0;

  return (
    <Link to={to} className={s.link}>
      <div className={s.wrapper}>
        <div>
          <h2 className={s.title}>{title}</h2>
          {content && <div className={s.content}>{content}</div>}
          <div className={s.author}>
            <div className={s.texts}>
              {!!articlesNb && (
                <div>{`${articlesNb} article${
                  mutlipleArticles ? "s" : ""
                }`}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Block;
