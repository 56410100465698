import React from "react";

import Logo from "@faq/assets/icons/colored_artelink.svg";

import * as s  from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={s.wrapper}>
      <Logo className={s.logo} />
      <div className={s.contact}>
        <a href="mailto:support@artelink.fr">Contact</a>
      </div>
    </footer>
  );
};

export default Footer;
