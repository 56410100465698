import { Link } from "gatsby";
import React from "react";

import * as s  from "./Breadcrumb.module.css";

type BreadcrumbProps = {
  breadcrumb: Array<{ to?: string; label: string }>;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb }) => (
  <div className={s.wrapper}>
    {breadcrumb.map(({ to, label }, i) => {
      if (to)
        return (
          <>
            <Link to={to} key={i}>
              {label}
            </Link>
            <span>{" > "}</span>
          </>
        );
      return (
        <span className={s.active} key={i}>
          {label}
        </span>
      );
    })}
  </div>
);

export default Breadcrumb;
