import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type SEOProps = {
  pageTitle?: string;
  pageDescription?: string;
  pageUrl?: string;
  pageImg?: string;
};

const SEO: React.FC<SEOProps> = ({
  pageTitle,
  pageDescription,
  pageUrl,
  pageImg,
}) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  if (defaults.siteUrl === "" && typeof window !== "undefined") {
    defaults.baseUrl = window.location.origin;
  }

  const url = `${defaults.siteUrl || defaults.baseUrl}${pageUrl || ""}`;

  const title = pageTitle ? `${pageTitle} | ${defaults.title}` : defaults.title;

  const descriptionArticle = () => {
    if (!pageDescription)
      return `${defaults.description}`
    else
      return pageDescription.concat('', '...')
  }

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={descriptionArticle()} />
      {pageImg && <meta name="image" content={pageImg} />}

      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={descriptionArticle()} />
      {pageImg && <meta property="og:image" content={pageImg} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={descriptionArticle()} />
      {pageImg && <meta name="twitter:image" content={pageImg} />}
    </Helmet>
  );
};

export default SEO;
