// extracted by mini-css-extract-plugin
export var closeIcon = "Header-module--closeIcon--6v2u0";
export var header = "Header-module--header--WSOJ8";
export var icon = "Header-module--icon--ol-lI";
export var input = "Header-module--input--4f2n3";
export var inputWrapper = "Header-module--inputWrapper--quYlu";
export var link = "Header-module--link--TKJ9n";
export var linkIcon = "Header-module--linkIcon--tyTu2";
export var logo = "Header-module--logo--smNTi";
export var searchIcon = "Header-module--searchIcon--kePti";
export var title = "Header-module--title--xky+l";
export var wrapper = "Header-module--wrapper--yUvXV";