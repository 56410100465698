import React from "react";

import * as s  from "./Wrapper.module.css";

type WrapperProps = {
  className?: string;
};

const Wrapper: React.FC<WrapperProps> = ({ className = "", children }) => (
  <div className={`${className} ${s.wrapper}`}>{children}</div>
);

export default Wrapper;
